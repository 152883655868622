<template>
  <div>
    <header class="header-pdf-view grey lighten-3">
      <button style="color:transparent; cursor:pointer; border:none; outline:none;" @click="$router.go(-1)">
        <div class="back-btn" role="link">
          <v-icon size="38">mdi-chevron-left</v-icon>
        </div>
      </button>
      <div>
        <v-btn
          icon
          @click="document === 'mtr' ? downloadMtrPdf() : downloadCdfPdf()"
          :loading="downloadingDocumentPdf"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </div>
    </header>
    <div class="pdf-area">
      <pdf
        v-if="pdfLink"
        :src="pdfLink"
        style="width: 70%"/>
    </div>
    <div class="loader-position">
      <div v-if="loadingPdf" class="loader"></div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf';
import mtrService from '@/services/mtr-service';

export default {
  name: 'MtrPdfShow',
  components: {
    mtrService,
    pdf,
  },
  data() {
    const { id, document } = this.$route.params;
    return {
      pdfLink: null,
      downloadingDocumentPdf: false,
      loadingPdf: true,
      id,
      document,
    }
  },

  mounted () {
    if (this.document === 'mtr') this.generateMtrPdfLink();
    else if(this.document === 'cdf') this.generateCdfPdfLink();
  },
  methods: {
    generateMtrPdfLink() {
      mtrService.mtrReport(this.id)
        .then((data) => { this.pdfLink = window.URL.createObjectURL(data); })
        .catch((err) => {
          this.toggleSnackbar({
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        }).finally(() => {
          this.downloadingDocumentPdf = false;
          this.loadingPdf = false;
        });
    },

    generateCdfPdfLink() {
      mtrService.mtrCdfReport(this.id)
        .then((data) => { this.pdfLink = window.URL.createObjectURL(data); })
        .catch((err) => {
          this.toggleSnackbar({
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        }).finally(() => {
          this.downloadingDocumentPdf = false;
          this.loadingPdf = false;
        });
    },

    downloadMtrPdf() {
      this.downloadingDocumentPdf = true;
      mtrService.show(this.id)
        .then(({ data: mtr}) => {
          const link = document.createElement('a');
          link.href = this.pdfLink;
          link.download = `${mtr.number}.pdf`;
          link.click();
        }).finally(() => {
          this.downloadingDocumentPdf = false;
        });
    },

    downloadCdfPdf() {
      this.downloadingDocumentPdf = true;
      mtrService.mtrCdfShow(this.id)
        .then((data) => {
          const link = document.createElement('a');
          link.href = this.pdfLink;
          link.download = `${data.number}.pdf`;
          link.click();
        }).finally(() => {
          this.downloadingDocumentPdf = false;
        });
    },
  },
};
</script>

<style lang="scss">
.header-pdf-view {
  z-index: 999;
  position: relative;
  top: 0;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.grey.lighten-3 {
  background-color: #eee;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: loading .8s linear infinite;
}
.loader-position {
  z-index: 99;
  position: fixed;
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.pdf-area {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 20px;
}

.back-btn {
  display: flex;
  width: 38px;
  border-radius: 4px;
  transition: .3s;
  &:hover {
    background: #dfdfdf;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(100deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>