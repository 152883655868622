var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("header", { staticClass: "header-pdf-view grey lighten-3" }, [
      _c(
        "button",
        {
          staticStyle: {
            color: "transparent",
            cursor: "pointer",
            border: "none",
            outline: "none",
          },
          on: {
            click: function ($event) {
              return _vm.$router.go(-1)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "back-btn", attrs: { role: "link" } },
            [
              _c("v-icon", { attrs: { size: "38" } }, [
                _vm._v("mdi-chevron-left"),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "", loading: _vm.downloadingDocumentPdf },
              on: {
                click: function ($event) {
                  _vm.document === "mtr"
                    ? _vm.downloadMtrPdf()
                    : _vm.downloadCdfPdf()
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-download")])],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "pdf-area" },
      [
        _vm.pdfLink
          ? _c("pdf", {
              staticStyle: { width: "70%" },
              attrs: { src: _vm.pdfLink },
            })
          : _vm._e(),
      ],
      1
    ),
    _c("div", { staticClass: "loader-position" }, [
      _vm.loadingPdf ? _c("div", { staticClass: "loader" }) : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }